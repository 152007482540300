<template>
  <div>
    <div class="card-container" :class="[getSizeClass, getVariantClass]">
      <div class="card-header">
        <div class="left">{{ title }}</div>
        <div class="right">
          <slot name="icon">
            <i class="icon" :class="icon"></i>
          </slot>
        </div>
      </div>
      <div class="card-body">
        <div class="left">{{ symbol ? `${symbol} ` : '' }}{{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryCardAlt',
  props: {
    title: { required: false, type: String },
    symbol: { required: false, type: String },
    value: { required: false, type: [String, Number] },
    icon: { required: false, type: String },
    size: { type: String, default: 'normal' },
    variant: { type: String, default: 'blue' },
  },
  computed: {
    getSizeClass: function() {
      const validSizes = {
        small: 'card-size-small',
        normal: 'card-size-normal',
        large: 'card-size-large',
      }
      return Object.keys(validSizes).includes(this.size)
        ? validSizes[this.size]
        : validSizes['normal']
    },
    getVariantClass: function() {
      const validVariants = [
        'deep-orange',
        'orange',
        'light-orange',
        'deep-green',
        'green',
        'light-green',
        'deep-red',
        'red',
        'light-red',
        'deep-blue',
        'blue',
        'light-blue',
        'deep-yellow',
        'yellow',
        'light-yellow',
        'deep-yellow',
        'yellow',
        'light-yellow',
        'deep-purple',
        'purple',
        'light-purple',
        'deep-gray',
        'gray',
        'light-gray',
      ]
      let cssClass = 'variant-blue'

      if (!validVariants.includes(this.variant)) {
        return cssClass
      }
      cssClass = `variant-${this.variant}`
      return cssClass
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  display: block;
  @apply px-4 py-3 rounded-xl;
}

.card-container.card-size-small {
  min-width: 160px;
  @apply h-20;
}
.card-container.card-size-normal {
  min-width: 190px;
  @apply h-20;
}
.card-container.card-size-large {
  min-width: 232px;
  @apply h-20;
}

.card-container.variant-deep-orange {
  @apply border-t border-b border-l border-r border-orange-700;
}
.card-container.variant-orange {
  @apply border-t border-b border-l border-r border-orange-500;
}
.card-container.variant-light-orange {
  @apply border-t border-b border-l border-r border-orange-400;
}
.card-container.variant-deep-gray {
  @apply border-t border-b border-l border-r border-gray-700;
}
.card-container.variant-gray {
  @apply border-t border-b border-l border-r border-gray-500;
}
.card-container.variant-light-gray {
  @apply border-t border-b border-l border-r border-gray-400;
}
.card-container.variant-deep-yellow {
  @apply border-t border-b border-l border-r border-yellow-700;
}
.card-container.variant-yellow {
  @apply border-t border-b border-l border-r border-yellow-500;
}
.card-container.variant-light-yellow {
  @apply border-t border-b border-l border-r border-yellow-400;
}

.card-container.variant-deep-blue {
  @apply border-t border-b border-l border-r border-blue-700;
}
.card-container.variant-blue {
  @apply border-t border-b border-l border-r border-blue-500;
}
.card-container.variant-light-blue {
  @apply border-t border-b border-l border-r border-blue-400;
}
.card-container.variant-deep-green {
  @apply border-t border-b border-l border-r border-green-700;
}
.card-container.variant-green {
  @apply border-t border-b border-l border-r border-green-500;
}
.card-container.variant-light-green {
  @apply border-t border-b border-l border-r border-green-400;
}

.card-container.variant-deep-red {
  @apply border-t border-b border-l border-r border-red-700;
}
.card-container.variant-red {
  @apply border-t border-b border-l border-r border-red-500;
}
.card-container.variant-light-red {
  @apply border-t border-b border-l border-r border-red-400;
}

.card-container.variant-deep-purple {
  @apply border-t border-b border-l border-r border-purple-700;
}
.card-container.variant-purple {
  @apply border-t border-b border-l border-r border-purple-500;
}
.card-container.variant-light-purple {
  @apply border-t border-b border-l border-r border-purple-400;
}
.card-title {
  padding-top: 6px;
  font-weight: 400;
  text-transform: uppercase;
  @apply text-sm;
}
.card-body,
.card-header {
  display: flex;
  justify-content: space-between;
}
.card-body .left {
  padding: 4px;
  @apply font-bold text-oDark;
}
.card-body .right {
  padding-right: 10px;
}
.card-body .right .icon {
  font-size: 28px;
}

.card-header .left {
  padding-top: 6px;
  font-weight: 400;
  @apply text-sm text-gray-500;
}
</style>
